@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Caveat:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Caveat:wght@400..700&family=Comfortaa:wght@300..700&display=swap");

$max_width: 1100px;

@media screen and (max-width: 476px) {
    .gallery {
        #media_0 {
            img {
                margin-top: 40px !important;
                height: 280px !important;
            }
        }
        #media_1 {
            img {
                margin-bottom: 50px !important;
                height: 280px !important;
            }
        }
        #media_2 {
            img {
                margin-top: 50px !important;
                height: 350px !important;
                box-shadow: 0px 50px 100px -15px rgba(60, 33, 27, 0.5) !important;
            }
        }
        #media_3 {
            img {
                margin-top: 0px !important;
            }
        }
    }
    .footer {
        .links {
            column-gap: 20px !important;
        }
    }
    .about {
        .play_button {
            &.play {
                display: none;
            }
        }
    }
    .come_registration {
        .title {
            text-align: center;
            width: 100%;
            max-width: none !important;
            font-size: 30px !important;
        }
        .desc {
            font-size: 20px !important;
        }
    }
}

.App {
    @apply bg-primary h-auto w-full;
    /* height: 10000px; */
}

body.menu_opened {
    .burger-menu {
        @apply top-[20px] right-[15px] fixed;
        background-color: #000000cb;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        padding: 2px;
        z-index: 300;
        color: white;
    }
}

//классы по секциям страницы
.header {
    @apply w-full flex justify-center sm:border-b-4 border-white;
    padding: 0 20px;

    .header_container {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        max-width: $max_width;

        > .l_block {
            flex: 1 0 auto;
            .logo {
            }
        }

        > .r_block {
            @apply relative sm:static sm:mt-auto sm:mb-6;

            position: relative;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            gap: 35px;

            /* .phonenumber {
                @apply sm:hidden;
                font-size: 20px;
                font-weight: 500;
                font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
            } */
            .burger-menu {
                @apply sm:scale-150 sm:mr-2;
                cursor: pointer;
                z-index: 101;
                transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

                &:hover {
                    transform: scale(1.1) rotateX(40deg);
                }
            }
        }
        .my_name {
            @apply flex right-[0px] bottom-[-30px] md:left-[50%] md:translate-x-[-50%] md:bottom-[-110px] md:px-3;
            position: absolute;
            width: max-content;
            letter-spacing: 2px;
            flex-flow: row nowrap;
            align-items: center;
            gap: 10px;
            z-index: 2;
            font-family: "Amatic SC", sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 28px;
            line-height: 24px;
            max-width: 100vw;

            > .r_block {
                display: flex;
                flex-flow: column wrap;

                .my_name_container {
                    display: flex;
                    flex-flow: row wrap;
                    flex: 1 0 auto;
                    gap: 5px;

                    span {
                        display: inline-block;
                        &::first-letter {
                            color: rgb(255, 94, 0);
                        }
                    }

                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        border-bottom: 2px solid black;
                        margin-bottom: 3px;

                        animation: blink 0.5s linear infinite;
                    }
                }
                .about_person {
                    @apply text-left sm:text-right;

                    font-size: 22px;
                    font-weight: 600;
                }
            }
            > .l_block {
                min-width: 60px;
                width: 60px;
                height: 60px;
                overflow: hidden;
                border-radius: 50%;
                img {
                }
            }
        }
    }
    .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        transition: 0.2s ease-in-out;

        .overlay {
            position: relative;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(5px) brightness(90%);
        }
        .menu_container {
            position: absolute;
            right: 0;
            top: 0;

            width: 100%;
            max-width: 320px;
            height: 100%;
            background: #000000b6;

            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            .top_block {
                .logo_container {
                    width: 100%;
                    background-color: #fff;
                    padding: 10px 10px 10px 30px;
                }

                .links {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: end;
                    color: white;
                    font-size: 24px;
                    padding: 30px 30px 0 0;

                    font-family: "Comfortaa", sans-serif;
                    font-weight: 700;
                    gap: 10px;

                    a {
                        display: flex;
                        width: 100%;
                        word-wrap: none;
                        white-space: nowrap;
                        align-items: center;
                        gap: 15px;

                        &::before {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 2px;
                            margin-left: 20px;
                            background-color: #fff;
                            transition: 0.3s ease;
                        }
                        &:hover {
                            &::before {
                                margin-left: 50px;
                            }
                        }
                        &.active {
                            color: #1f98d4;
                        }
                    }
                }
            }

            .menu_sosials {
                display: flex;
                justify-content: center;
                flex-flow: column nowrap;
                gap: 20px;
                padding: 10px 10px 20px 10px;

                .socials_block {
                    display: flex;
                    justify-content: center;
                }

                .reg_button {
                    display: block;
                    font-size: 20px;
                    background-color: #1f98d4;
                    color: white;
                    border-radius: 10px;
                    padding: 15px 15px;
                    text-align: center;
                    line-height: 20px;
                    
                    &:hover {
                        transform: translateY(-5px) scale(1.1);
                    }
                }
            }
        }
    }
}

.Title {
    @apply m-[40px_10px_0px_10px] md:mt-[130px];
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;

    .title_container {
        display: flex;
        flex-flow: column nowrap;
        gap: 40px;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

        .title {
            @apply sm:text-[40px] sm:leading-[40px] text-[62px] leading-[62px];

            text-align: center;
            letter-spacing: -1px;
            font-weight: 500;
        }

        .description {
            @apply sm:text-[17px] text-[19px];

            text-align: center;
            color: gray;
        }
    }
    .services_link {
        @apply text-link;
        font-size: 27px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 auto;
        gap: 10px;
        width: fit-content;

        &:hover {
            text-decoration: underline;
        }
    }
}

.gallery {
    width: 100%;
    max-width: calc($max_width + 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px auto 0 auto;

    .gallery_container {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        width: 100%;

        .bg_line {
            @apply sm:h-[100%] sm:object-cover;
            position: absolute;
            z-index: 0;
            width: 100%;
        }

        #media_0 {
            img {
                margin-top: 150px;
                height: 450px;
            }
        }
        #media_1 {
            img {
            }
        }
        #media_2 {
            img {
                box-shadow: 0px 50px 100px -15px rgba(60, 33, 27, 0.49);
                margin-top: 100px;
                height: 350px;
            }
        }
        #media_3 {
            img {
                margin-top: 40px;
            }
        }

        > div {
            @apply sm:flex-[1_1_45%] sm:max-w-[44%] flex-[1_1_25%] max-w-[24%];

            position: relative;
            z-index: 1;
            height: 100%;
        }

        * > img {
            @apply sm:h-[300px] h-[500px];
            object-fit: cover;
            box-shadow: 0px 50px 100px -15px rgba(60, 33, 27, 0.1);
        }
    }
}

.strategy {
    max-width: $max_width;
    margin: 70px auto 0 auto;
    padding: 0 20px;

    > h2 {
        position: relative;
        line-height: 60px;
        font-size: 60px;
        font-weight: 600;

        &::before {
            width: 10px;
            left: -14px;
            border-right: 4px solid black;
        }
        &::after {
            width: 42px;
            left: -8px;
            border-bottom: 3px solid black;
        }

        &::after,
        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: -8px;
            height: 42px;
        }
    }
    .list {
        margin-top: 40px;

        li {
            @apply flex md:flex-col;

            min-height: 120px;
            justify-content: space-between;

            &:nth-child(2n + 1) {
                background-color: #fff;
            }

            .title_li {
                @apply flex-[0_1_33%] md:flex-auto;

                font-weight: 600;
                font-size: 24px;
                margin: 30px 0 30px 30px;
                color: #30221f;
            }

            .desc_li {
                @apply flex-[0_1_67%] md:flex-auto w-[60%] md:w-auto md:mx-[30px] m-[20px_30px_20px_0] flex;
                color: #89807d;
                font-size: 17px;

                align-items: center;
                gap: 10px;

                > div {
                    max-width: 93%;
                }
                > .point {
                    max-width: 7%;
                    min-width: 30px;
                }
            }
        }
    }
}

.about {
    @apply flex md:flex-col;
    $height_media: 600px;
    $height_preview: 0px;

    max-width: calc($max_width + 200px);
    margin: 100px auto 0 auto;
    gap: 90px;
    padding: 0 30px 30px 30px;
    overflow: hidden;

    .text_content {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column nowrap;

        gap: 30px;
        margin: 5% 0 0 0;

        .title {
            font-size: 54px;
            line-height: 54px;
            font-weight: 600;
        }
        .description {
            font-size: 28px;
            text-indent: 1.25cm;
        }
    }

    .slider_container {
        position: relative;
        @apply bg-primary;

        flex: 1 1 35%;
        min-width: 34%;
        max-height: 100%;

        .swiper_view {
            height: $height_media;

            video,
            img {
                width: 100% !important;
                height: $height_media !important;
                object-fit: cover !important;
            }
        }
        .swiper_preview {
            $height_preview: calc(100% - $height_media);
            margin-top: 25px;
            height: $height_preview;

            .play_button {
                display: none;
            }

            .swiper {
                height: 100%;
            }

            .slide_gallery {
                cursor: pointer;
                transition: 0.2s;
                &:hover {
                    transform: scale(0.9);
                }
            }
        }
        .slide_gallery {
            @apply bg-primary;
            height: 100%;
            border-radius: 10px;
            /*       overflow: hidden; */

            .swiper-lazy-preloader {
                z-index: 0;
            }
            video,
            img {
                position: relative;
                z-index: 1;
            }

            .play_button {
                opacity: 0.7;
                z-index: 5450;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: 0.2s ease-in-out;
                width: 70px;
                height: 70px;
                background-color: #ffffff;
                border-radius: 50%;
                overflow: hidden;
                cursor: pointer;
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;

                &.pause {
                    mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0748 7.50835C9.74622 6.72395 8.25 7.79065 8.25 9.21316V14.7868C8.25 16.2093 9.74622 17.276 11.0748 16.4916L15.795 13.7048C17.0683 12.953 17.0683 11.047 15.795 10.2952L11.0748 7.50835ZM9.75 9.21316C9.75 9.01468 9.84615 8.87585 9.95947 8.80498C10.0691 8.73641 10.1919 8.72898 10.3122 8.80003L15.0324 11.5869C15.165 11.6652 15.25 11.8148 15.25 12C15.25 12.1852 15.165 12.3348 15.0324 12.4131L10.3122 15.2C10.1919 15.271 10.0691 15.2636 9.95947 15.195C9.84615 15.1242 9.75 14.9853 9.75 14.7868V9.21316Z' fill='%231C274C'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z' fill='%231C274C'/%3E%3C/svg%3E");
                    transition: 0.4s;
                }
                &.play {
                    mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='10' stroke='%231C274C' stroke-width='1.5'/%3E%3Cpath d='M8 9.5C8 9.03406 8 8.80109 8.07612 8.61732C8.17761 8.37229 8.37229 8.17761 8.61732 8.07612C8.80109 8 9.03406 8 9.5 8C9.96594 8 10.1989 8 10.3827 8.07612C10.6277 8.17761 10.8224 8.37229 10.9239 8.61732C11 8.80109 11 9.03406 11 9.5V14.5C11 14.9659 11 15.1989 10.9239 15.3827C10.8224 15.6277 10.6277 15.8224 10.3827 15.9239C10.1989 16 9.96594 16 9.5 16C9.03406 16 8.80109 16 8.61732 15.9239C8.37229 15.8224 8.17761 15.6277 8.07612 15.3827C8 15.1989 8 14.9659 8 14.5V9.5Z' stroke='%231C274C' stroke-width='1.5'/%3E%3Cpath d='M13 9.5C13 9.03406 13 8.80109 13.0761 8.61732C13.1776 8.37229 13.3723 8.17761 13.6173 8.07612C13.8011 8 14.0341 8 14.5 8C14.9659 8 15.1989 8 15.3827 8.07612C15.6277 8.17761 15.8224 8.37229 15.9239 8.61732C16 8.80109 16 9.03406 16 9.5V14.5C16 14.9659 16 15.1989 15.9239 15.3827C15.8224 15.6277 15.6277 15.8224 15.3827 15.9239C15.1989 16 14.9659 16 14.5 16C14.0341 16 13.8011 16 13.6173 15.9239C13.3723 15.8224 13.1776 15.6277 13.0761 15.3827C13 15.1989 13 14.9659 13 14.5V9.5Z' stroke='%231C274C' stroke-width='1.5'/%3E%3C/svg%3E");
                }
                &:hover {
                    opacity: 1;
                    transform: translate(-50%, -50%) scale(1.3) !important;
                }
            }
        }
        .swiper-cube-shadow {
            display: none;
        }

        &.play {
            .play_button {
                opacity: 0;
            }
        }
        &:hover {
            &.play {
                .play_button {
                    opacity: 1;
                }
            }
        }
    }
}

.row_gallery {
    @apply bg-secondary;

    margin: 30px 0 0 0;
    padding: 100px 30px 30px 30px;
    width: 100%;
    overflow: hidden;

    .gallery_container {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        margin-left: -125px;
        animation: 20s movement_right_left ease-in-out infinite;

        .media {
            @apply sm:min-h-[200px]  sm:min-w-[200px] sm:w-[200px] sm:h-[200px] w-[250px] h-[250px] min-h-[250px] min-w-[250px];
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.reviews_container {
    @apply bg-secondary;
    width: 100%;

    > h2 {
        font-family: "Comfortaa", sans-serif;
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
        padding: 0 30px;
    }

    .reviews {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        gap: 20px;
        padding: 10px 30px 80px 30px;

        .review {
            box-shadow: 0 19px 30px rgba(161, 161, 161, 0.3), 0 15px 12px rgba(179, 179, 179, 0.1);

            background-color: #fff;
            border-radius: 10px;
            border: 2px solid #b3cfe5;
            width: fit-content;
            height: fit-content;
            max-width: 500px;
            padding: 30px;
            display: flex;
            flex-flow: column nowrap;

            gap: 20px;

            .rating {
                .empty-icons {
                    display: flex;
                }
            }

            .description {
                text-indent: 20px;
                font-weight: 400;
                font-size: 18px;
            }

            .user {
                margin: auto 0 0 0;
                display: flex;
                align-items: center;
                gap: 25px;

                .avatar {
                    border-radius: 50%;
                    overflow: hidden;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 60px;
                    height: 60px;
                    min-width: 60px;
                    min-height: 60px;
                }
                .name {
                    font-weight: 600;
                }
            }
        }
    }
}

.about_2 {
    @apply bg-secondary;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 30px;

    $black: #1d1f20;
    $blue: #83e4e2;
    $green: #56b6ed;
    $yellow: #ec32fd;
    $white: #fafafa;

    %boxshadow {
        box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
    }
    font-family: "Comfortaa", sans-serif;
    font-weight: 500;

    .title {
        font-size: 45px;
        font-weight: 600;
        text-align: center;
        line-height: 45px;
    }
    ol.gradient-list {
        @apply m-[40px_auto_40px_200px] lg:m-[40px_auto];
        counter-reset: gradient-counter;
        list-style: none;
        padding-left: 1rem;
        max-width: $max_width;

        > li {
            font-weight: 600;
            /*  width: fit-content; */
            height: fit-content;
            background: white;
            border-radius: 0 0.5rem 0.5rem 0.5rem;
            @extend %boxshadow;
            counter-increment: gradient-counter;
            margin-top: 1rem;
            min-height: 3rem;
            padding: 1rem 1rem 1rem 3rem;
            position: relative;
            font-size: 22px;
            &::before,
            &::after {
                background: linear-gradient(135deg, $blue 0%, $green 100%);
                border-radius: 1rem 1rem 0 1rem;
                content: "";
                height: 3rem;
                left: -1rem;
                overflow: hidden;
                position: absolute;
                top: -1rem;
                width: 3rem;
            }
            &::before {
                align-items: flex-end;
                @extend %boxshadow;
                content: counter(gradient-counter);
                color: $black;
                display: flex;
                font: 900 1.5em/1 "Montserrat";
                justify-content: flex-end;
                padding: 0.125em 0.25em;
                z-index: 1;
            }
            @for $i from 1 through 5 {
                &:nth-child(10n + #{$i}):before {
                    background: linear-gradient(
                        135deg,
                        rgba($green, $i * 0.2) 0%,
                        rgba($yellow, $i * 0.2) 100%
                    );
                }
            }
            @for $i from 6 through 10 {
                &:nth-child(10n + #{$i}):before {
                    background: linear-gradient(
                        135deg,
                        rgba($green, 1 - (($i - 5) * 0.2)) 0%,
                        rgba($yellow, 1 - (($i - 5) * 0.2)) 100%
                    );
                }
            }
            + li {
                margin-top: 2rem;
            }
        }
    }
}

.come_registration {
    background-color: #f1cbbc;
    padding: 30px 20px 40px 20px;

    .overlay {
    }

    .content {
        display: flex;
        flex-flow: column nowrap;
        gap: 20px;
        max-width: $max_width;
        margin: 0 auto;
        font-family: Verdana, Geneva, Tahoma, sans-serif;

        .title {
            font-size: 26px;
            line-height: 30px;
            font-weight: 600;
            max-width: 70%;
        }
        .desc {
            font-size: 18px;
            text-indent: 1.25cm;
        }
        .reg_button {
            margin: 0 0 0 auto;
            font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
            background: #1f4fd4;
            color: white;
            border: 2px solid white;
            box-shadow: 0px 3px 10px rgba(148, 148, 148, 0.651);
            border-radius: 10px;
            padding: 10px 15px;
            width: fit-content;
            font-size: 24px;
            &:hover {
                transform: translateY(-5px) scale(1.1);
            }
        }
    }
}

.footer {
    width: 100%;
    min-width: calc(100vw - 30px - 30px);
    padding: 100px 30px 40px 30px;
    background-color: #0d0d0d;
    color: white;
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 30px;

    h2,
    .have_questions {
        text-align: center;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    }
    h2 {
        font-size: 50px;
        font-weight: 600;
        line-height: 40px;
    }
    .have_questions {
        color: #dfdfdf;
        line-height: 24px;
        font-size: 24px;
        font-weight: 400;
        max-width: 700px;
    }
    .links {
        @apply flex flex-row justify-center flex-wrap text-[20px];
        column-gap: 80px;

        .local_link {
            &.active {
                @apply text-orange;
            }
        }
    }
    .line {
        width: 100%;
        max-width: $max_width;
        height: 2px;
        background-color: #fff;
    }
    .rights {
        font-size: 20px;
        color: gray;
    }
}

.socials {
    display: flex;
    align-items: center;
    gap: 20px;

    a {
        width: 80px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        transition: 0.2s ease-in-out;

        &:hover {
            transform: translateY(-5px) scale(1.1);
        }
    }
    a[data-messenger="whatsapp"] {
        background-color: #24d366;
    }
    a[data-messenger="telegram"] {
        background-color: #1f98d4;
    }
    a[data-messenger="email"] {
        background-color: #f89200;
        color: white;
    }
}

@keyframes movement_right_left {
    from,
    to {
        margin-left: -125px;
    }

    25% {
        margin-left: -35px;
    }

    75% {
        margin-left: -250px;
    }
}

@keyframes blink {
    from,
    to {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
}
