@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    min-height: 100vh;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

html.menu_opened,
body.menu_opened {
    overflow: hidden;
}

@media (pointer: fine){
    html.menu_opened {
        padding-right: 15px;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    @apply h-full w-full;
}

.swiper_cube {

}
